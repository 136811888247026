<template>
    <div class="vue-box">

        <div class="header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item @click="sa_admin.changeTo('operation-index')">项目实施</el-breadcrumb-item>
                <el-breadcrumb-item>项目详情</el-breadcrumb-item>
            </el-breadcrumb>

        </div>

        <div class="title">施工日志</div>
        <div class="c-panel">
            <el-row justify="center">
                <el-col :span="5">
                    <el-row>
                        <el-col>项目名称</el-col>
                        <el-col style="font-weight: bold;margin-top: 10px;">{{one.name}}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="5">
                    <el-row>
                        <el-col>记录日期：</el-col>
                        <el-col style="font-weight: bold;margin-top: 10px;">{{one.createTime}}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="5">
                    <el-row>
                        <el-col>记录人员：</el-col>
                        <el-col style="font-weight: bold;margin-top: 10px;">{{one.creatorName}}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="9">
                </el-col>
            </el-row>
        </div>

        <div class="c-panel">
            <el-descriptions title="日志信息" :column="2" border>
                <el-descriptions-item label="原定计划">{{one.plan}}</el-descriptions-item>
                <el-descriptions-item label="明日计划">{{one.planNext}}</el-descriptions-item>
           
                <el-descriptions-item label="施工记录及主要问题" :span="2">
                    {{one.issue}}
                </el-descriptions-item>
               
                <el-descriptions-item label="上传附件" :span="2">
                    <el-link v-for="item in one.filenameFiles" :key="item.name"
                    :href="item.url" type="primary">{{item.name}}</el-link>
                </el-descriptions-item>
            
            </el-descriptions>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            one: {}
        }
    },

    created(){
        this.one = this.sa_admin.params;
        this.one.filenameFiles = JSON.parse(this.one.filename);
    },
}
</script>

<style>
.header {
    margin: 20px;
}

.title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2d2d2d;
    line-height: 29px;
    padding-left: 20px;
    border-left: 3px solid #4983f4;
    margin-left: 20px;
}
</style>